import { useState } from 'react';
import { useSignup } from "../hooks/useSignup"

import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  InputGroup,
  InputRightElement,
  Text,
  Box,
} from '@chakra-ui/react';
import {ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { BounceLoader } from 'react-spinners';






const Signup = () => {
  const [school, setSchool] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const {signup, error, isLoading} = useSignup()

  const handleSubmit = async (e) => {
    e.preventDefault()

    await signup(school, email, password)
 }

  return (
    <form onSubmit={handleSubmit}>
      <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <Heading fontSize={'2xl'}>Create an account</Heading>
          <FormControl id="school" isRequired>
            <FormLabel>School</FormLabel>
            <Input
              variant={'filled'}
              type="school"
              onChange={(e) => setSchool(e.target.value)}
              value={school}
            />
          </FormControl>
          <FormControl id="email" isRequired>
            <FormLabel>Email address</FormLabel>
            <Input 
              variant={'filled'} 
              type="email" 
              onChange={(e) => setEmail(e.target.value)}
              value={email} 
            />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input 
                   variant={'filled'} 
                   onChange={(e) => setPassword(e.target.value)}
                   value={password}
                   placeholder="Example@1234"
                  type={showPassword ? 'text' : 'password'} 
               />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
          </FormControl>
          <Stack spacing={6}>
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              align={'start'}
              justify={'space-between'}>
              <Checkbox>Remember me</Checkbox>
              <Link color={'pink.500'}>Forgot password?</Link>
            </Stack>
              <Button
                disabled={isLoading}
                type="submit"
                loadingText="Submitting"
                size="lg"
                bg={'pink.200'}
                color={'black'}
                _hover={{
                  bg: 'pink.300',
                }}>
                {isLoading ? <BounceLoader size={30}  /> : 'Sign up'} 
              </Button>
          </Stack>
            <Stack pt={6}>
              <Text align={'center'}>
                Already a user? <Link href='/login'>  Login </Link>
              </Text>
            </Stack>
            <Box>
               {error && <Text color={'red.400'} textAlign={'center'}>{error}</Text>}
            </Box>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          src={
            'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80'
          }
        />
      </Flex>
    </Stack>
    </form>
   
  )
}

export default Signup