import React from "react";
import { Routes, Route, } from 'react-router-dom'
import Home from "./Home";
import { HelmetProvider } from 'react-helmet-async';
import QuizInstructions from "./pages/QuizInstructions";
import EnglishQuizSheet from "./pages/EnglishQuizSheet";
import EnglishShs from "./pages/EnglishShs";
import EnglishJhs from "./pages/EnglishJhs";
import SStudiesJhs from "./pages/SStudiesJhs";
import SStudiesJhsSheet from "./pages/SStudiesJhsSheet";
import EnglishJhsSheet from "./pages/EnglishJhsSheet";
import Signup from "./pages/signup";
import Login from "./pages/login";
import TrialShs from "./pages/TrialShs";
import TrialQuizzer from "./pages/TrialQuizzer";
import Footer from "./components/Footer";
import IntScience from "./pages/IntScience";
import MathsShs from "./pages/MathsCore";
import MathsJhs from "./pages/MathsJhs"
import MathsJhsSheet from "./pages/MathsJhsSheet"
import IntSciQuizsheet from "./pages/IntsciQuizsheet";
import IntSciJhs from "./pages/IntSciJhs";
import IntSciJhsSheet from "./pages/IntSciJhsSheet";
import MathsQuizsheet from "./pages/MathsQuizSheet";
import ReviseAnswers from "./pages/ReviseAnswers";
import ReviseAnswers40 from "./pages/ReviewAnswers40";
import EnglishJhsSheetStrict from "./pages/EnglishJhsSheetStrict"
import ReviseAnswers30 from "./pages/RevisewAnswers30"


function App() {
  

  return (
    <HelmetProvider>
      <>
        <Routes>
            <Route path="/" exact element={<Home />} />  
            <Route path="/quizinstructions" exact element={<QuizInstructions />} />
            <Route path="/trialShs" exact element={<TrialShs />} />
            <Route path="/englishquizsheet" exact element={<EnglishQuizSheet />} />
            <Route path="/englishJhsSheet" exact element={<EnglishJhsSheet />} />
            <Route path="/englishJhs" exact element={<EnglishJhs />} />
            <Route path="/trialquizzer" exact element={<TrialQuizzer />} />
            <Route path="/englishShs" exact element={<EnglishShs />} />
            <Route path="/intscience" exact element={<IntScience />} />
            <Route path="/intsciquizsheet" exact element={<IntSciQuizsheet />} />
            <Route path="/mathsShs" exact element={<MathsShs />} />
            <Route path="/mathsJhs" exact element={<MathsJhs />} />
            <Route path="/mathsquizsheet" exact element={<MathsQuizsheet />} />
            <Route path="/mathsJhsSheet" exact element={<MathsJhsSheet />} />
            <Route path="/signup" exact element={<Signup />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/reviseAnswers" exact element={<ReviseAnswers />} />
            <Route path="/reviseAnswers40" exact element={<ReviseAnswers40/>} />
            <Route  path="/reviseAnswers30" exact element={<ReviseAnswers30/>} />
            <Route path="/sStudiesJhs" exact element={<SStudiesJhs />}  />
            <Route path="/sStudiesJhsSheet" exact element={<SStudiesJhsSheet />} />
            <Route path="/intsciJhsSheet" exact element={<IntSciJhsSheet />} />
           <Route path="/intsciJhs" exact element={<IntSciJhs />} />
           <Route path="/englishJhsSheetStrict" exact element={<EnglishJhsSheetStrict />} />
        </Routes>
        <Footer />
      </>
    </HelmetProvider>   
  );
}

export default App;
