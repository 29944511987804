import { Box, Button, Flex, Spacer } from '@chakra-ui/react'
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

export default function EnglishJhsSheetStrict() {

    function onNext(){
        console.log('next')
    }

    function onPrev(){
        console.log('prev')
    }

  return (
    <>
        <HelmetProvider >
            <Helmet><title>Quiz Page</title></Helmet>
        </HelmetProvider>
        <Box
            color={'gray.900'}
            bg={('gray.300')}
            p={'10'}
            maxW={800}
            my={'10'}
            mx={[4, 4, 5, 'auto']}
            borderRadius={5}
        >


        

            <Flex mt={10}>
                <Button borderRadius={0} bg={'yellow.300'} color={'black'} onClick={onPrev}>Prev</Button>
                <Spacer />
                <Button borderRadius={0} bg={'green.300'} color={'black'} onClick={onNext}>Next</Button>
            </Flex>
        </Box>
      
    </>
  )
}
