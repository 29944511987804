import {
   Box,
   Flex,
   Text,
   IconButton,
   Button,
   Stack,
   Collapse,
   Icon,
   Link,
   Popover,
   PopoverTrigger,
   PopoverContent,
   useColorModeValue,
   useBreakpointValue,
   useDisclosure,
   useColorMode,
   HStack,
   Hide,
  Image
 } from '@chakra-ui/react';
 import { BiUserCircle } from 'react-icons/bi'
 import {
   HamburgerIcon,
   CloseIcon,
   ChevronDownIcon,
   ChevronRightIcon,
 } from '@chakra-ui/icons';
 import { MoonIcon, SunIcon } from '@chakra-ui/icons';
 import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import logo from '../assets/img/logoblack2.png'
import useIdle from '../hooks/useIdleTimer';
import { NavLink } from 'react-router-dom';
 
 export default function Navbar() {

  const { logout } = useLogout()
   const { user } = useAuthContext()

   const handleClick = () => {
    logout()
 }
   const { colorMode, toggleColorMode } = useColorMode();
   const { isOpen, onToggle } = useDisclosure();

   return (
     <Box bg={useColorModeValue('transparent', 'transparent')}
       position="fixed" 
       backgroundColor="rgba(255, 255, 255, 0.8)"
       backdropFilter="saturate(180%) blur(5px)"
       w="100%"
       zIndex={1}
     >        
      <Flex
           bg={useColorModeValue('transparent', 'transparent')}
           color={useColorModeValue('gray.600', 'white')}
           minH={'60px'}
           py={{ base: 2 }}
           px={{ base: 4 }}
           borderBottom={1}
           borderStyle={'solid'}
           borderColor={useColorModeValue('gray.200', 'gray.900')}
           align={'center'}
         >
           <Flex
             flex={{ base: 1, md: 'auto' }}
             ml={{ base: -2 }}
             display={{ base: 'flex', md: 'none' }}>
             <IconButton
               onClick={onToggle}
               icon={
                 isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
               }
               variant={'ghost'}
               aria-label={'Toggle Navigation'}
             />
           </Flex>
           <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
             <Box
               textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
               as='a'
               href={'/'}
             >
               <Image src={logo} alt={"logo"} />
             </Box>

             <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
               <DesktopNav />
             </Flex>
           </Flex>

           <Stack
             flex={{ base: 1, md: 0 }}
             justify={'flex-end'}
             direction={'row'}
             spacing={6}>
             <Button p={2} size={20} onClick={toggleColorMode}>
               {colorMode === 'dark' ? <MoonIcon /> : <SunIcon />}
             </Button>

             {user && (
               <HStack>
                 <Hide below='md'>
                   <BiUserCircle size={20} />
                   <Text color={'black'}>{user.email}</Text>
                 </Hide>
                 <Button
                    color={'black'}
                   onClick={handleClick}
                   as={'a'}
                   fontSize={'sm'}
                   fontWeight={400}
                   variant={'link'}
                   href={'/'}
                 >
                   Logout
                 </Button>
               </HStack>
             )}
             {!user && (
               <HStack>
                 <Button
                   as={'a'}
                   color={'black'}
                   fontSize={'sm'}
                   fontWeight={400}
                   variant={'link'}
                   href={'/login'}>
                   Sign In
                 </Button>
                 <Button
                   display={{ base: 'none', md: 'inline-flex' }}
                   fontSize={'sm'}
                   color={'black'}
                   fontWeight={600}
                   href={'/signup'}
                   as='a'
                   _hover={{
                     bg: 'gray.900',
                     color: 'white'
                   }}>
                   Sign Up
                 </Button>
               </HStack>
             )}

           </Stack>
         </Flex>
       <Collapse in={isOpen} animateOpacity>
         <MobileNav />
       </Collapse>
     </Box>
   );
 }
 
 const DesktopNav = () => {
   const linkColor = useColorModeValue('gray.600', 'gray.600');
   const linkHoverColor = useColorModeValue('gray.800', 'white');
   const popoverContentBgColor = useColorModeValue('white', 'gray.800');
   const { user } = useAuthContext()
   const { logout } = useLogout()
   const { isIdle } = useIdle({ onIdle: logout , idleTimer: 30})
   return (
     <Stack direction={'row'} spacing={4} alignItems={'center'}>
       {(user && !isIdle ? USER_NAV : NON_USER_NAV).map((navItem) => (
         <Box key={navItem.label}>
           <Popover trigger={'hover'} placement={'bottom-start'}>
             <PopoverTrigger>
               <Link
                as={NavLink}
                 p={2}
                 href={navItem.href ?? '#'}
                 fontSize={'sm'}
                 fontWeight={500}
                 color={linkColor}
                 _hover={{
                   textDecoration: 'none',
                   color: linkHoverColor,
                 }}>
                 {navItem.label}
               </Link>
             </PopoverTrigger>
 
             {navItem.children && (
               <PopoverContent
                 border={0}
                 boxShadow={'xl'}
                 bg={popoverContentBgColor}
                 p={4}
                 rounded={'xl'}
                 minW={'sm'}>
                 <Stack>
                   {navItem.children.map((child) => (
                     <DesktopSubNav key={child.label} {...child} />
                   ))}
                 </Stack>
               </PopoverContent>
             )}
           </Popover>
         </Box>
       ))}
     </Stack>
   );
 };
 
 const DesktopSubNav = ({ label, href, subLabel }) => {
   return (
     <Link
       href={href}
       role={'group'}
       display={'block'}
       p={2}
       rounded={'md'}
       _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
       <Stack direction={'row'} align={'center'}>
         <Box>
           <Text
             transition={'all .3s ease'}
             _groupHover={{ color: 'pink.400' }}
             fontWeight={500}>
             {label}
           </Text>
           <Text fontSize={'sm'}>{subLabel}</Text>
         </Box>
         <Flex
           transition={'all .3s ease'}
           transform={'translateX(-10px)'}
           opacity={0}
           _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
           justify={'flex-end'}
           align={'center'}
           flex={1}>
           <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
         </Flex>
       </Stack>
     </Link>
   );
 };
 
 const MobileNav = () => {
  const { user } = useAuthContext()
   const { logout } = useLogout()
   const { isIdle } = useIdle({ onIdle: logout, idleTimer: 10 })
   return (
     <Stack
       bg={useColorModeValue('white', 'gray.800')}
       p={4}
       display={{ md: 'none' }}>
       { (user && !isIdle ? USER_NAV : NON_USER_NAV).map((navItem) => (
         <MobileNavItem key={navItem.label} {...navItem} />
       ))}
     </Stack>
   );
 };
 
 const MobileNavItem = ({ label, children, href }) => {
   const { isOpen, onToggle } = useDisclosure();
 
   return (
     <Stack spacing={4} onClick={children && onToggle}>
       <Flex
         py={2}
         as={Link}
         href={href ?? '#'}
         justify={'space-between'}
         align={'center'}
         _hover={{
           textDecoration: 'none',
         }}>
         <Text
           fontWeight={600}
           color={useColorModeValue('gray.600', 'gray.200')}>
           {label}
         </Text>
         {children && (
           <Icon
             as={ChevronDownIcon}
             transition={'all .25s ease-in-out'}
             transform={isOpen ? 'rotate(180deg)' : ''}
             w={6}
             h={6}
           />
         )}
       </Flex>
 
       <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
         <Stack
           mt={2}
           pl={4}
           borderLeft={1}
           borderStyle={'solid'}
           borderColor={useColorModeValue('gray.200', 'gray.700')}
           align={'start'}>
           {children &&
             children.map((child) => (
               <Link key={child.label} py={2} href={child.href}>
                 {child.label}
               </Link>
             ))}
         </Stack>
       </Collapse>
     </Stack>
   );
 };
 
 const USER_NAV = [
   {
    
     label: 'WASSCE',
     children: [
       {
         label: 'English',
         subLabel: 'Take a full objective test with standard timer',
         href: '/EnglishShs',
       },
       {
         label: 'Maths',
         subLabel: 'Take a full objective test with standard timer',
         href: '/mathsShs',
       },
       {
         label: 'Integrated Science',
         subLabel: 'Take a full objective test with standard timer',
         href: '/intscience',
       },
       {
        label: 'Biology',
         subLabel: 'Take a full objective test with standard timer',
        href: '#',
      },
      {
        label: 'Chemistry',
        subLabel: 'Take a full objective test with standard timer',
        href: '#',
      },
      {
        label: 'Physics',
        subLabel: 'Take a full objective test with standard timer',
        href: '#',
      }
     ],
   },
   {
     label: 'BECE',
     children: [
       {
         label: 'English Language',
         subLabel: 'English objective for JHS',
         href: '/englishJhs',
       },
       {
         label: 'Maths',
         subLabel: 'Maths objective for JHS',
         href: '/mathsJhs',
       },
       {
         label: 'Integrated Science',
         subLabel: 'Science objective for JHS',
         href: '/intsciJhs',
       },
       {
         label: 'Social Studies',
         subLabel: 'Social Studies objective for JHS',
         href: '/sStudiesJhs',
       },
     ],
   },
   {
     label: 'GENERAL',
     children: [
       {
         label: 'Aptitude Test',
         subLabel: 'How smart are you',
         href: '#',
       },
       {
         label: 'Current Affairs',
         subLabel: 'Update your knowledge',
         href: '#',
       },
       {
         label: 'International',
         subLabel: 'world wide facts',
         href: '#',
       },
     ],
   },
 ];


 const NON_USER_NAV = [
  {
    label: 'Learn Everyday',
    href: '/quizzer',
  },
  {
    label: 'Lets Try',
    children: [
        {
           label: 'Instructions',
           subLabel:'Read the guides to start',
           href: '/quizinstructions'
        },
        {
           label: 'Quick Start',
           subLabel: 'Trial',
           href: '/trialShs'
        }
    ]
  },
  {
    label: 'School Ranking Gh',
    children: [
        {
           label: 'University',
           subLabel:'Latest University Ranking',
           href: '/quizinstructions'
        },
        {
           label: 'High School',
           subLabel: 'Categories',
           href: '/trialShs'
        }
    ]
  },
 
 ]