import { useState } from "react";
import { useAuthContext } from './useAuthContext'
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

export const useSignup = () => {
   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)
   const { dispatch } = useAuthContext()
   const toast = useToast()

   const navigate = useNavigate()

   const signup = async (school, email, password) => {
      setIsLoading(true)
      setError(null)

      const response = await fetch(process.env.REACT_APP_NOT_SECRET_CODE + '/quizzer/user/signup', {
         method: 'POST',
         headers: {'Content-Type': 'application/json'},
         body: JSON.stringify({school, email, password})
      })
      const json = await response.json()

      if (!response.ok) {
         setIsLoading(false)
         setError(json.error)
      }
      if (response.ok) {
         // save the user to local storage
         localStorage.setItem('user', JSON.stringify(json))

         // success toast
         toast({
            title: 'Account created successfully.',
            description: "We've created your account.",
            position: 'top',
            status: 'success',
            duration: 9000,
            isClosable: true,
         })

         // update the auth context
         dispatch({type: 'LOGIN', payload: json})

         setIsLoading(false)
         
         navigate('/login')
      }
   }

   
   return {signup, isLoading, error }
}
