import React, { useState } from "react";
import { Box, Button, Flex, Heading, HStack, ListItem, Spacer, Text, UnorderedList, VStack} from "@chakra-ui/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import correctSound from '../assets/audio/correctSound.mp3'
import wrongSound from '../assets/audio/wrongSound.mp3'
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Timer from "../components/CountDownTimer60";





const TrialQuizzer = () => {
  const [showFinalScore, setShowFinalScore] = useState(false)
  const [currentQn, setCurrentQn] = useState(0)
  const [scores, setScores] = useState(0)
  const [isPlaying, setIsplaying] = useState(false)
  const [wAnswer, setWAnswer] = useState(0)
 

  const correctAudio = React.createRef()
  const wrongAudio = React.createRef()
  const navigate = useNavigate()

  const location = useLocation();
  const data = location.state;
  console.log(data);

  
const correctAnswer = () => {
  setScores(scores + 1) 
  if (currentQn + 1 < data.questions.length) {
    setCurrentQn(currentQn + 1)
  }else {
    setShowFinalScore(true)
  }
}

const wrongAnswer = () => {
  setWAnswer(wAnswer + 1)
  if (currentQn + 1 < data.questions.length) {
    setCurrentQn(currentQn + 1)
  }else {
    setShowFinalScore(true)
  }
  
}

 const handleOptionClick = (e) => {
  if(currentQn + 1 >= 1) {
    setIsplaying(true)
  }else {
    setIsplaying(false)
  }

    if (e.target.innerHTML === data.questions[currentQn].answer) {
      correctAudio.current.play()
      correctAnswer()
    }else {
      wrongAudio.current.play()
      wrongAnswer()
    } 
  }

const quitBtn = () => {
  if (window.confirm('Are you sure you want to quit?')) {
    navigate('/')
 }

}

  const restartGame = () => {
    setScores(0)
    setCurrentQn(0)
    setShowFinalScore(false)
    setIsplaying(false)
    setScores(0)
    setWAnswer(0)
  }


  let remarks = ''
  const percentScore = (scores/data.questions.length) * 100

  if(percentScore <= 30) {
    remarks = 'You need more practice'
  }else if (percentScore > 30 && percentScore <= 50) {
    remarks = 'Better luck next time'
  }else if (percentScore <= 70 && percentScore > 50) {
    remarks = 'You can do better'
  }else if (percentScore >= 71 && percentScore <= 84) {
    remarks = 'You did great!'
  }else {
    remarks = 'You|\'re an absolute genius '
  }
  

  return ( 
    <>
      <HelmetProvider >
        <Helmet><title>Start Quiz</title></Helmet> 
      </HelmetProvider>

      <Box
        color={'gray.900'}
        bg={('gray.300')}
        p={'10'}
        maxW={800} 
        my={'10'}
        mx={[4,4,5,'auto']}
        borderRadius={5}
      >
        {showFinalScore ? (
          <>
           <HelmetProvider >
              <Helmet><title>Final Score</title></Helmet> 
           </HelmetProvider>
            <Box>
              <VStack spacing={3}>
                <Heading>Quiz has ended</Heading>
                <Text></Text>
                <Text> {scores} out {data.questions.length} </Text>
                <Text color={'blue.500'} fontSize='4xl'>Your Score: {percentScore}%</Text>
                <Text>{remarks}</Text>
              </VStack>
              <Box mb={5} mt={5}>
              <Flex>
                  <UnorderedList listStyleType={'none'}>
                    <ListItem>Number Questions:</ListItem><br />
                    <ListItem>Attempted Questions:</ListItem><br />
                    <ListItem>Correct Answers:</ListItem><br />
                    <ListItem>Wrong Answers:</ListItem>
                  </UnorderedList>
                  <Spacer/>
                  <UnorderedList listStyleType={'none'}>
                    <ListItem>{data.questions.length}</ListItem><br/>
                    <ListItem>{(scores + wAnswer)}</ListItem><br/>
                    <ListItem>{scores}</ListItem><br/>
                    <ListItem>{wAnswer}</ListItem>
                  </UnorderedList>
                </Flex>
              </Box>
              <HStack>
                <Button onClick={restartGame}>Restart</Button>
                <Button onClick={quitBtn}>Quit</Button>
              </HStack>
              <Text mt={5} align='center' fontStyle={'italic'}>Signup to practice full exams and have access to different subjects</Text>
            </Box>
          </>
        ) : (
          <>
            <HelmetProvider >
              <Helmet><title>Quiz Page</title></Helmet> 
            </HelmetProvider>
            <>
              <audio ref={wrongAudio}  src={wrongSound} />
              <audio ref={correctAudio}  src={correctSound}  />
            </>
            <Box>
              <VStack>
                <Heading align={'center'}>ENGLISH LANGUAGE</Heading>
                <Text>OBJECTIVE TEST</Text>
                <Text>WASSCE {data.month} {data.year} </Text>
              </VStack>
              <Flex alignItems={'center'}>
                <Text>{currentQn + 1} of {data.questions.length}</Text>
                    <Spacer />
                <Timer setShowFinalScore={setShowFinalScore} isPlaying={isPlaying} />
              </Flex>
              <Text fontStyle={'italic'} align={'center'} m={5}>{data.questions[currentQn].instructions}</Text>
            </Box>
            <Box>
              <Heading size={'s'} mb={'5'}>
                {data.questions[currentQn].question}
              </Heading>
              <HStack mb={'5'} spacing={200}>
                <Button onClick={handleOptionClick} >
                  {data.questions[currentQn].optionA}
                </Button>
                <Button onClick={handleOptionClick} >
                  {data.questions[currentQn].optionB}
                </Button>
              </HStack>
              <HStack spacing={200}>
                <Button onClick={handleOptionClick} >
                  {data.questions[currentQn].optionC}
                </Button>
                <Button onClick={handleOptionClick} >
                  {data.questions[currentQn].optionD}
                </Button>
              </HStack>
            </Box>
            <Box>
            <HStack mt={'10'} spacing={50}>
               <Button onClick={quitBtn}>Quit</Button>
               <Button><Link to={'/trialShs'}>Back</Link></Button>
            </HStack>
            </Box>
          </>
        )}
      </Box>
    </>
   );
}
 
export default TrialQuizzer;