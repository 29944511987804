import { Box, Center, Flex, Heading, ListItem, Spacer, Text, UnorderedList, Link } from "@chakra-ui/react";
import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import answer from '../assets/img/answer.png'
import options from '../assets/img/options.png'

const QuizInstructions = () => {
   return ( 
      <>
         <HelmetProvider>
            <Helmet><title>Quizzer Instructions</title></Helmet>
         </HelmetProvider>

         <Center m={[5,20]}>
            <Box>
               <Heading as='h4' align={'center'} >
                  How to Start the Quiz
               </Heading>
               <Text align={'center'}>Ensure you read the entire Guide</Text>
               <UnorderedList p={5}>
                  <ListItem mb={5}> The quiz has a duration of 60mins and ends as soon as your time elapses </ListItem>
                  <ListItem mb={5}>Each quiz has standard 100 objectives exam as Wassce, Bece ...</ListItem>
                  <ListItem>
                     Every question has 4 options
                     <img height={800} width={800} src={options} alt="Quiz options example" />
                  </ListItem>
                  <ListItem mt={5} mb={5}>
                     Select the option which best answers the question by clicking it
                     <img height={800} width={800} src={answer} alt='Quiz answer example' />
                  </ListItem>
               </UnorderedList>
                  <Flex>
                     <Link  href={'/tryquiz'}>Lets Start</Link>
                        <Spacer />
                     <Link href={'/'} >No take me back</Link>
                  </Flex>
            </Box>
         </Center>
      </>
    );
}
 
export default QuizInstructions;