import { Grid, Heading, Box, Container, Image, Button, useColorModeValue, Badge, Link, Hide,Text, Show } from "@chakra-ui/react";
import React from "react";
import { motion } from "framer-motion"
import { Helmet } from "react-helmet-async";
import Navbar from "./components/Navbar";
import organicChemistry2 from "./assets/img/organicChemistry2.jpg"
import physics from "./assets/img/physics.jpg"
import maths from './assets/img/maths.jpg'
import biology from './assets/img/biology.jpg'
import english2 from './assets/img/english2.jpg'
import accounting from './assets/img/accounting.jpg'
import economics from './assets/img/economics.jpg'
import geography from './assets/img/geography.jpg'
import general from './assets/img/general.jpg'
import blob1 from './assets/img/blob1.png'
import gif1 from './assets/img/online.gif'
import gif2 from './assets/img/gif3.gif'
import bg2 from './assets/img/bg2.png'
import bg3 from './assets/img/bg3.png'
import { ArrowForwardIcon } from '@chakra-ui/icons'

const Home = () =>  ( 
   <>
      <Helmet><title>Quizonnet - Home</title></Helmet>
      {/* nav */}
      <Navbar />
      <Box 
         bg={useColorModeValue('gray.50', 'gray.800')}
         pt={20}
      >     
         {/* hero */}
         <Box>
            <Container
               maxW="container.xl"
            >
               <Box
                  bgImage={blob1}
                  bgPosition="left"
                  display={'Flex'}
                  alignItems={"center"}
                  py={['10', '20']}
                  flexDirection={'row'}>
                  <Box mr={[2, 4]}>
                     <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
                        <Text
                           as={'span'}>
                           Prepare for your
                        </Text>
                        <br />
                        <Text as={'span'} color={'pink.400'}>
                           final exams!
                        </Text>
                     </Heading>
                     <Box mt={'6'} >
                        <Text color='gray.500' fontSize='md'> WAEC has been known for years to repeat old past questions usually from the past 10-15 years. So what this means is you should be regularly practicing with your past question</Text>
                     </Box>
                  </Box>
                  <Hide below='sm'>
                     <Box w='100%'
                        bgImage={blob1}
                        bgPosition='left'
                     >
                        <img src={gif1} alt='gif' />
                     </Box>
                  </Hide>
               </Box>
            </Container>
         </Box>
         {/* six boxes */}
         <Box>
            <Container
               maxW={'container.md'}
               mt={2}
               py='5'
            >
               <Grid templateColumns="repeat(3, 1fr)">
                  <Box
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     m={[1, 2]}>
                     <Image
                        w='80%'
                        h={[100, 180]}
                        borderRadius='lg'
                        src={general}
                        alt='general'
                     />
                     <Button
                        rightIcon={<ArrowForwardIcon />}
                        colorScheme="teal" variant='link'
                        mt='5'>
                        General
                     </Button>
                  </Box>
                  <Box
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     m={[1, 2]}>
                     <Image
                        w='80%'
                        h={[100, 180]}
                        borderRadius='lg'
                        src={english2}
                        alt='chemistry'
                     />
                     <Button
                        rightIcon={<ArrowForwardIcon />}
                        colorScheme="teal" variant='link'
                        mt='5'>
                        English
                     </Button>
                  </Box>
                  <Box
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     m={[1, 2]}>
                     <Image
                        w='80%'
                        h={[100, 180]}
                        borderRadius='lg'
                        src={maths}
                        alt='chemistry'
                     />
                     <Button
                        rightIcon={<ArrowForwardIcon />}
                        colorScheme="teal" variant='link'
                        mt='5'>
                        Maths
                     </Button>
                  </Box>
               </Grid>
            </Container>

            <Container
               maxW={'container.md'}
               mt={10}

            >
               <Grid templateColumns="repeat(3, 1fr)">
                  <Box
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     m={[1, 2]}>
                     <Image
                        w='80%'
                        h={[100, 180]}
                        borderRadius='lg'
                        src={organicChemistry2}
                        alt='chemistry'
                     />
                     <Button
                        rightIcon={<ArrowForwardIcon />}
                        colorScheme="teal" variant='link'
                        mt='5'>
                        Chemistry
                     </Button>
                  </Box>
                  <Box
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     m={[1, 2]}>
                     <Image
                        w='80%'
                        h={[100, 180]}
                        borderRadius='lg'
                        src={biology}
                        alt='biology'
                     />
                     <Button
                        rightIcon={<ArrowForwardIcon />}
                        colorScheme="teal" variant='link'
                        mt='5'>
                        Biology
                     </Button>
                  </Box>
                  <Box
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     m={[1, 2]}>
                     <Image
                        w='80%'
                        h={[100, 180]}
                        borderRadius='lg'
                        src={physics}
                        alt='physics'
                     />
                     <Button
                        rightIcon={<ArrowForwardIcon />}
                        colorScheme="teal" variant='link'
                        mt='5'>
                        Physics
                     </Button>
                  </Box>
               </Grid>
            </Container>
         </Box>
         {/*gif */}
         <Box>
            <Show
               below="sm"
            >
               <Box
                  as={motion.div}
                  animate={{x: 0}}
                  initial={{x: -1000}}
                  w='100%'
               >
                  <img src={gif2} alt='gif' />
               </Box>
            </Show>
         </Box>
         {/* pills */}
         <Box>
            <Container
               maxW='container.md'
               mt={10}>
               <Heading align='center'>Master these most examinable topics </Heading>
               <Box
                  display='flex'
                  direction='row'
                  flexWrap='wrap'
                  justifyContent='space-between'
                  my={10}
               >
                  <Box
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                  >
                     <Badge
                        borderRadius='3xl'
                        px={4}
                        py={2}
                        mr='4'
                        mb='4'
                        colorScheme='fff'
                        bg={useColorModeValue('blue.100', 'blue.900')}
                        textTransform='none'
                        as='a'
                     >
                        Digestive System
                     </Badge>
                  </Box>
                  <Box
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                  >
                     <Badge
                        borderRadius='3xl'
                        px={4}
                        py={2}
                        mr='4'
                        mb='4'
                        color='fff'
                        bg={useColorModeValue('blue.100', 'blue.900')}
                        textTransform='none'
                        as='a'
                     >
                        Calcullus
                     </Badge>
                  </Box>


                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     Force
                  </Badge>
                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     venn Diagram
                  </Badge>
                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     Trignometry
                  </Badge>
                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     Integers
                  </Badge>
                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     Matrix
                  </Badge>
                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     Phrasal Verbs
                  </Badge>
                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     Pythogorems theory
                  </Badge>
                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     Osmosis & Difussion
                  </Badge>
                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     Indices
                  </Badge>
                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     Synonyms
                  </Badge>
                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     Pressure
                  </Badge>
                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     Organic Chemistry
                  </Badge>
                  <Badge
                     as={motion.div}
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     borderRadius='3xl'
                     px={4}
                     py={2}
                     mr='4'
                     mb='4'
                     color='fff'
                     bg={useColorModeValue('blue.100', 'blue.900')}
                     textTransform='none'
                  >
                     Energy
                  </Badge>
               </Box>
            </Container>
         </Box>
         {/*bg */}
         <Box>
            <Show
               below="sm"
            >
               <Box
                  as={motion.div}
                  animate={{ x: 0 }}
                  initial={{ x: -1000 }}
                  w='100%'
               >
                  <img src={bg2} alt='bg' />
               </Box>
            </Show>
         </Box>

         {/* How Quiz Sheet Works*/}
         <Box>
            <Container maxW='container.md' my='20'>
               <Heading as={'h3'} align='center' pb='2'>
                  How Quizonnet Works
               </Heading>
               <Box align='center' maxW={'700px'} mx='auto' pb='10'>
                  <Text fontSize={'sm'} fontWeight='bold'>As the saying goes, Pratice makes man perfect, Quizonnet is specifically designed to help students revise, learn and practice on past Wassce, Bece and the most examinable questions. Students have access to a myrid of questions pratically designed to test the students smartness and time conciousness. Quizzes are taken with a standard timer in respect to the actual time awarded for the particular subject in a real exam sitting. Students are hence advised to practice within the timings to prepare them for their respective final exams.</Text>
               </Box>
               <Heading align='center' fontSize={'md'}> UpComing Quiz Sheets</Heading>
               <Grid templateColumns='repeat(3, 1fr)' gap='6' my={10}>
                  <Box mx='auto' align='center'>
                     <Image w='100%' borderRadius='full' boxSize='100px' src={accounting} mb='3' />
                     <Heading size='xs'>
                        <Link>
                           <Box pb='4'>
                              Accounting
                           </Box>
                        </Link>
                     </Heading>
                  </Box>
                  <Box mx='auto' align='center'>
                     <Image w='100%' borderRadius='full' boxSize='100px' src={geography} mb='3' />
                     <Heading size='xs'>
                        <Link>
                           <Box pb='4'>
                              Geography
                           </Box>
                        </Link>
                     </Heading>
                  </Box>
                  <Box mx='auto' align='center'>
                     <Image w='100%' borderRadius='full' boxSize='100px' src={economics} mb='3' />
                     <Heading size='xs'>
                        <Link>
                           <Box pb='4'>
                              economics
                           </Box>
                        </Link>
                     </Heading>
                  </Box>
               </Grid>
            </Container>
         </Box>
         {/*bg */}
         <Box>
            <Show
               below="sm"
            >
               <Box
                  as={motion.div}
                  animate={{ x: 0 }}
                  initial={{ x: -1000 }}
                  w='100%'
               >
                  <img src={bg3} alt='bg' />
               </Box>
            </Show>
         </Box>
      </Box>
   </>
   
);

export default Home;




