import { useAuthContext } from '../hooks/useAuthContext';
import { Button, Flex, Grid, Spacer, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import {BeatLoader} from 'react-spinners'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Heading,
    Box,
    Text
} from '@chakra-ui/react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Timer from '../components/CountDownTimer60';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';

const MathsShs = () => {
    const [questions, setQuestions] = useState(null)
    const { user } = useAuthContext()

    useEffect(() => {
        const fetchQuestions = async () => {
            const response = await fetch(process.env.REACT_APP_NOT_SECRET_CODE + '/quizzer/questions/jhs/maths', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const data = await response.json()

            if (response.ok) {
                setQuestions(data)
                console.log(data)
            }
        }

        if (user) {
            fetchQuestions()
        }

    }, [user])

    return (
    <>
        <>
            <HelmetProvider >
                <Helmet><title>Maths Core Quizzer</title></Helmet>
            </HelmetProvider>
            <Navbar />
        </>
        {!questions ? (
            <Box pt={150} pb={60} align="center">
            <Text>Loading questions...</Text>
            <BeatLoader size={48} color='pink' loading />
            </Box>    
         ) : (
        <Box
            maxW={900}
            pt={100}
            pb={60}
            mx={[4, 4, 5, 'auto']}
            borderRadius={5}
        >
            <Accordion allowToggle m={10} >
             {questions && questions.map((question) => (
                 <AccordionItem key={question._id}>
                 <h2>
                    <AccordionButton _expanded={{ bg: 'teal', color: 'white' }}>
                    <Box flex='1' textAlign='left'>
                        Bece {question.subject} {question.month} {question.year}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <Box>
                        <VStack>
                            <Heading align={'center'}>MATHEMATICS</Heading>
                            <Text>OBJECTIVE TEST</Text>
                            <Text> BECE {question.month} {question.year} </Text>
                        </VStack>
                        <Flex alignItems={'center'}>
                            <Text>1 of 40</Text>
                            <Spacer />
                            <Timer />
                        </Flex>
                    </Box>
                    <Box>
                        <Text fontWeight={'bold'} size={'s'} mb={'5'}>
                            {question.questions[0].question}
                        </Text>

                        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
                            <Box
                                    as='button'
                                    maxW='lg'
                                    height='40px'
                                    borderRadius='5px'
                                    bg={'gray.100'}
                                    color={'gray.900'}
                                    _hover={{ bg: 'gray.200' }}
                                >
                                    <Text noOfLines={[3, 2, 1]} fontWeight='bold' fontSize={['sm', 'md']}>{question.questions[0].optionA}</Text>
                            </Box>
                            <Box
                                    as='button'
                                    maxW='lg'
                                    height='40px'
                                    borderRadius='5px'
                                    bg={'gray.100'}
                                    color={'gray.900'}
                                    _hover={{ bg: 'gray.200' }}
                                >
                                    <Text noOfLines={[3, 2, 1]} fontWeight='bold' fontSize={['sm', 'md']}>{question.questions[0].optionB}</Text>
                            </Box>
                            <Box
                                    as='button'
                                    maxW='lg'
                                    height='40px'
                                    borderRadius='5px'
                                    bg={'gray.100'}
                                    color={'gray.900'}
                                    _hover={{ bg: 'gray.200' }}
                                >
                                    <Text noOfLines={[3, 2, 1]} fontWeight='bold' fontSize={['sm', 'md']}>{question.questions[0].optionC}</Text>
                            </Box>
                            <Box
                                    as='button'
                                    maxW='lg'
                                    height='40px'
                                    borderRadius='5px'
                                    bg={'gray.100'}
                                    color={'gray.900'}
                                    _hover={{ bg: 'gray.200' }}
                                >
                                    <Text noOfLines={[3, 2, 1]} fontWeight='bold' fontSize={['sm', 'md']}>{question.questions[0].optionD}</Text>
                            </Box>
                        </Grid>
                        <Button mt={10}>
                            <Link to={'/mathsJhsSheet'} state={question}>Start Quiz </Link>
                        </Button>
                    </Box>
                </AccordionPanel>
                </AccordionItem>
                ))}
                </Accordion>
            </Box> 
        )}     
    </>
    );
}

export default MathsShs;