import { useAuthContext } from '../hooks/useAuthContext';
import { Button, Flex, Grid, HStack, Spacer, useBoolean, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Box, 
  Text
} from '@chakra-ui/react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Timer from '../components/CountDownTime30';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { BeatLoader } from 'react-spinners';
import { Radio, RadioGroup } from '@chakra-ui/react'


const EnglishJhs = () => {
  const [questions, setQuestions] = useState(null)
  const [value, setValue] = useState()
  const [easyMode, setEasyMode] = useBoolean()
  const {user} = useAuthContext()

  useEffect(() => {
    const fetchQuestions = async () => {
      const response = await fetch(process.env.REACT_APP_NOT_SECRET_CODE + '/quizzer/questions/jhs/english',{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
       }
      }) 
      const data = await response.json()

      if(response.ok) {
        setQuestions(data)
        console.log(data)
      }
    }

    if(user) {
      fetchQuestions()
    }

  }, [user])

  return (  
    <>
      <HelmetProvider >
        <Helmet><title>English Jhs</title></Helmet>
      </HelmetProvider>
      <Navbar />
      {!questions ? (   
          <Box pt={150} pb={60} align="center">
            <Text>Loading questions...</Text>
            <BeatLoader size={48} color='pink' loading />
          </Box>
      ) : (
            <Box
              pt={100}
              pb={50}
              maxW={900}
              mb={10}
              mx={[4, 4, 5, 'auto']}
              borderRadius={5}
            >
              <Accordion allowToggle m={10} >
                {questions && questions.map((question) => (
                  <AccordionItem key={question._id}>
                    <h2>
                      <AccordionButton _expanded={{ bg: 'teal', color: 'white' }}>
                        <Box flex='1' textAlign='left'>
                          Bece {question.subject} {question.month} {question.year}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Box align={'left'}>  
                        <HStack>
                          <Button size={'xs'} onClick={setEasyMode.toggle}>
                            Strict Mode
                          </Button>
                          <Text fontSize={'xs'}>{easyMode ? "Yes" : "No" }</Text>
                        </HStack>                 
                        </Box>
                        <Box>
                         {easyMode ? (
                          <>
                            <Box>
                              <VStack>
                                <Heading align={'center'}>ENGLISH LANGUAGE</Heading>
                                <Text>OBJECTIVE TEST</Text>
                                <Text> BECE {question.month} {question.year} </Text>
                                <Heading size={'md'} align={'center'}>{question.questions[0].part}</Heading>
                                <Heading size={'md'} align={'center'}>{question.questions[0].label}</Heading>
                              </VStack>
                              <Flex alignItems={'center'}>
                                <Text>1 of {question.questions.length}</Text>
                                <Spacer />
                                <Timer />
                              </Flex>
                              <Heading size="md" align={'center'} mb={3}>{question.questions[0].section}</Heading>
                              <Text align={'center'} m={5}>{question.questions[0].instructions}</Text>
                            </Box>
                            <Box>
                              <Text fontWeight={'bold'} size={'s'} mb={'5'}>
                                {question.questions[0].question}
                              </Text>
                              <RadioGroup onChange={setValue} value={value}>
                                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
                                  <Radio color={'red.300'} size='md' colorScheme='green' value={question.questions[0].optionA} >
                                    <Text fontWeight={'semibold'}>𝐀.   {question.questions[0].optionA}</Text> 
                                  </Radio>
                                  <Radio size='md' colorScheme='green' value={question.questions[0].optionB}>
                                    <Text fontWeight={'semibold'}>𝐁.   {question.questions[0].optionB}</Text>
                                  </Radio>
                                  <Radio size='md' colorScheme='green' value={question.questions[0].optionC}>
                                    <Text fontWeight={'semibold'}>𝐂.  {question.questions[0].optionC}</Text>
                                  </Radio>
                                  <Radio size='md' colorScheme='green' value={question.questions[0].optionD}>
                                    <Text fontWeight={'semibold'}>𝐃.  {question.questions[0].optionD}</Text>
                                  </Radio>
                                </Grid>
                              </RadioGroup>                           
                              <Flex mt={10}>
                              <Button borderRadius={0} bg={'yellow.300'} color={'black'}>Prev</Button>
                              <Spacer />
                              <Button borderRadius={0} bg={'green.300'} color={'black'}>Next</Button>
                              </Flex>
                              <Button borderRadius={0}  mt={10}>
                                <Link to={'/englishJhsSheetStrict'} state={question}>Start Quiz </Link>
                              </Button>
                            </Box>
                          </>
                         ) : (
                          <>
                              <Box>
                                <VStack>
                                  <Heading align={'center'}>ENGLISH LANGUAGE</Heading>
                                  <Text>OBJECTIVE TEST</Text>
                                  <Text> BECE {question.month} {question.year} </Text>
                                  <Heading size={'md'} align={'center'}>{question.questions[0].part}</Heading>
                                  <Heading size={'md'} align={'center'}>{question.questions[0].label}</Heading>
                                </VStack>
                                <Flex alignItems={'center'}>
                                  <Text>1 of {question.questions.length}</Text>
                                  <Spacer />
                                  <Timer />
                                </Flex>
                                <Heading size="md" align={'center'} mb={3}>{question.questions[0].section}</Heading>
                                <Text align={'center'} m={5}>{question.questions[0].instructions}</Text>
                              </Box>
                              <Box>
                                <Text fontWeight={'bold'} size={'s'} mb={'5'}>
                                  {question.questions[0].question}
                                </Text>

                                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
                                  <Button >
                                    {question.questions[0].optionA}
                                  </Button>
                                  <Button>
                                    {question.questions[0].optionB}
                                  </Button>
                                  <Button>
                                    {question.questions[0].optionC}
                                  </Button>
                                  <Button>
                                    {question.questions[0].optionD}
                                  </Button>
                                </Grid>
                                <Button mt={10}>
                                  <Link to={'/englishJhsSheet'} state={question}>Start Quiz </Link>
                                </Button>
                              </Box>
                          </>   
                         )}
                          
                        </Box>    
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>   
      )}     
    </>
   );
}
 
export default EnglishJhs;