
import Navbar from "../components/Navbar";
import { Button, Flex, HStack, Spacer, useColorModeValue, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Box, 
  Text
} from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async';
import Timer from '../components/CountDownTimer60';
import { Link } from 'react-router-dom';



const TrialShs = () => {
  const [questions, setQuestions] = useState(null)


  useEffect(() => {
    const fetchQuestions = async () => {
      const response = await fetch(process.env.REACT_APP_NOT_SECRET_CODE + '/quizzer/trial/shs') 
      const data = await response.json()

      if(response.ok) {
        setQuestions(data)
        console.log(data)
      }
    }
      fetchQuestions()
  }, [])

  return ( 
    <>
      <Box
      bg={useColorModeValue('gray.50', 'gray.800')}
    > 
      <>
        <Helmet><title>Try Quizzer</title></Helmet>
        <Navbar />
      </>
           <Accordion allowToggle m={10} >
             {questions && questions.map((question) => (
             <AccordionItem key={question._id}>
             <h2>
             <AccordionButton _expanded={{ bg: 'pink.200', color: 'white' }}>
                 <Box flex='1' textAlign='left'>
                   Wassce {question.subject} {question.month} {question.year}
                 </Box>
                 <AccordionIcon />
               </AccordionButton>
             </h2>
             <AccordionPanel pb={4}>
             <Box>
              <VStack>
                <Heading align={'center'}>ENGLISH LANGUAGE</Heading>
                <Text>OBJECTIVE TEST</Text>
                <Text>WASSCE {question.month} {question.year} </Text>
              </VStack>
              <Flex alignItems={'center'}>
                <Text> 1 of 100</Text>
                    <Spacer />
                <Timer />
              </Flex>
              <Text fontStyle={'italic'} align={'center'} m={5}>{question.questions[0].instructions}</Text>
              </Box>
              <Box>
              <Heading size={'s'} mb={'5'}>
                {question.questions[0].question}
              </Heading>
              <HStack mb={'5'} spacing={[100,200]}>
                <Button >
                  {question.questions[1].optionA}
                </Button>
                <Button>
                  {question.questions[1].optionB}
                </Button>
              </HStack>
              <HStack spacing={[100,200]}>
                <Button>
                  {question.questions[1].optionC}
                </Button>
                <Button>
                  {question.questions[1].optionD}
                </Button>
              </HStack>
              <Button mt={10}>
              <Link  to={'/trialquizzer'} state={question}>Start Quiz </Link>
              </Button>  
            </Box>
             </AccordionPanel>
           </AccordionItem>
           ))} 
         </Accordion> 
    </Box>
     
    </>
   );
}
 
export default TrialShs;