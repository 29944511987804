import React from "react";
import { Link,useNavigate, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {ListItem,List,ListIcon,Grid,GridItem, Heading,Box,Text,VStack,Image,HStack,Button} from '@chakra-ui/react'
import {MdCheckCircle} from "react-icons/md"




const ReviseAnswers = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const data  = location.state;
    console.log(data)

    return ( 
        <>
            <HelmetProvider >
                <Helmet><title>Review Answers</title></Helmet>
            </HelmetProvider>
            <Box>
            </Box>
            <Box m={10}>
                <VStack>
                    <Heading align={'center'}>{data.subject}</Heading>
                    <Text>OBJECTIVE ANSWERS</Text>
                    <Text> {data.type} {data.month} {data.year} </Text>
                </VStack>
            </Box>
                
            <Grid m={5} mb={10} templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
                <GridItem w='100%'>
                    <List spacing={3}>
                        <ListItem>
                             <Box align='center' m={1} >
                                <>
                                    {data.questions[0].img  ? (
                                        <>
                                        <HStack spacing={3} align='start'>
                                            <Text color='red' fontWeight='bold'>1.</Text>
                                         <>
                                         <VStack>
                                            <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                {data.questions[0].question}
                                            </Text>
                                            <Image m={[1, 2]} src={data.questions[0].img} />
                                            <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                {data.questions[0].sub1}
                                            </Text>
                                            <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                {data.questions[0].sub2}
                                            </Text>
                                            <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                {data.questions[0].sub3}
                                            </Text>
                                            <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                {data.questions[0].sub4}
                                            </Text>
                                         </VStack>
                                         </>
                                        </HStack>
                                            
                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>1.</Text>
                                            <>
                                            <VStack>
                                                <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                    {data.questions[0].question}
                                                </Text>
                                                <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                    {data.questions[0].sub1}
                                                </Text>
                                                <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                    {data.questions[0].sub2}
                                                </Text>
                                                <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                    {data.questions[0].sub3}
                                                </Text>
                                                <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                    {data.questions[0].sub4}
                                                </Text>
                                            </VStack>
                                            </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                 {data.questions[0].answerimg ? (
                                    <HStack spacing={1}>
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[0].answerimg} />
                                    </HStack>
                                    
                                ) : (
                                    <HStack spacing={1}>
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[0].answer}</Text>
                                    </HStack>  
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[1].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>2.</Text>
                                                <>
                                                <VStack>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[1].question}
                                                    </Text>
                                                    <Image m={[1, 2]} src={data.questions[1].img} />
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[1].sub1}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[1].sub2}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[1].sub3}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[1].sub4}
                                                    </Text>
                                                </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                    <Text color='red' fontWeight='bold'>2.</Text>
                                                <>
                                                <VStack>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[1].question}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[1].sub1}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[1].sub2}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[1].sub3}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[1].sub4}
                                                    </Text>
                                                </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[1].answerimg ? (
                                    <HStack spacing={1}>
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[1].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1}>
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[1].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[2].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>3.</Text>
                                                <>
                                                <VStack>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[2].question}
                                                    </Text>
                                                    <Image m={[1, 2]} src={data.questions[2].img} />
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[2].sub1}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[2].sub2}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[2].sub3}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[2].sub4}
                                                    </Text>
                                                </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                    <Text color='red' fontWeight='bold'>3.</Text>
                                                <>
                                                <VStack>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[2].question}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[2].sub1}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[2].sub2}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[2].sub3}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[2].sub4}
                                                    </Text>
                                                </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[2].answerimg ? (
                                    <HStack spacing={1}>
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[2].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1}>
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[2].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[3].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>4.</Text>
                                                <>
                                                <VStack>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[3].question}
                                                    </Text>
                                                    <Image m={[1, 2]} src={data.questions[3].img} />
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[3].sub1}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[3].sub2}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[3].sub3}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[3].sub4}
                                                    </Text>
                                                </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                    <Text color='red' fontWeight='bold'>4.</Text>
                                                <>
                                                <VStack>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[3].question}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[3].sub1}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[3].sub2}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[3].sub3}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[3].sub4}
                                                    </Text>
                                                </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[3].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[3].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[3].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[4].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>5.</Text>
                                                <>
                                                <VStack>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[4].question}
                                                    </Text>
                                                    <Image m={[1, 2]} src={data.questions[4].img} />
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[4].sub1}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[4].sub2}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[4].sub3}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[4].sub4}
                                                    </Text>
                                                </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                    <Text color='red' fontWeight='bold'>5.</Text>
                                                <>
                                                <VStack>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[4].question}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[4].sub1}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[4].sub2}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[4].sub3}
                                                    </Text>
                                                    <Text fontWeight={'bold'} size={'s'} mb={'2'}>
                                                        {data.questions[4].sub4}
                                                    </Text>
                                                </VStack>      
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[4].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[4].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[4].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[5].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>6.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[5].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[5].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[5].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[5].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[5].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[5].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>6.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[5].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[5].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[5].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[5].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[5].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[5].answerimg ? (
                                    <HStack spacing={1}>
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[5].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1}>
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[5].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[6].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>7.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[6].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[6].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[6].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[6].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[6].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[6].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>7.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[6].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[6].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[6].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[6].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[6].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[6].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[6].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[6].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[7].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>8.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[7].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[7].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[7].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[7].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[7].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[7].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>8.</Text>
                                                <>
                                                    <VStack>
                                                        <Text  fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[7].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[7].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[7].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[7].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[7].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[7].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[7].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1}>
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[7].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[8].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>9.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[8].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[8].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[8].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[8].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[8].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[8].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>8.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[8].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[8].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[8].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[8].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[8].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[8].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[8].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[8].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[9].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>10.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[9].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[9].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[9].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[9].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[9].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[9].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>10.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[9].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[9].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[9].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[9].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[9].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[9].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[9].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[9].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                    </List>
                </GridItem>
                <GridItem w='100%'>
                    <List spacing={3}>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[10].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>11.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[10].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[10].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[10].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[10].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[10].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[10].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>11.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[10].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[10].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[10].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[10].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[10].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[10].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[10].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[10].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[11].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>12.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[11].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[11].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[11].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[11].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[11].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[11].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>12.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[11].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[11].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[11].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[11].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[11].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[11].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[11].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[11].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[12].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>13.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[12].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[12].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[12].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[12].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[12].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[12].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>13.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[12].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[12].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[12].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[12].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[12].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[12].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[12].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[12].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[13].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>14.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[13].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[13].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[13].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[13].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[13].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[13].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>14.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[13].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[13].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[13].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[13].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[13].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[13].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[13].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[13].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[14].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>15.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[14].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[14].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[14].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[14].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[14].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[14].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>15.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[14].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[14].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[14].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[14].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[14].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[14].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[14].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[14].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[15].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>16.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[15].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[15].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[15].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[15].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[15].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[15].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>16.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[15].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[15].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[15].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[15].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[15].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[15].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[15].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[15].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[16].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>17.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[16].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[16].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[16].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[16].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[16].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[16].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>17.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[16].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[16].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[16].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[16].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[16].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[16].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[16].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[16].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                                <Box align='center' m={1} >
                                    <>
                                        {data.questions[17].img ? (
                                            <>
                                                <HStack spacing={3} align='start'>
                                                    <Text color='red' fontWeight='bold'>18.</Text>
                                                    <>
                                                        <VStack>
                                                            <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                                {data.questions[17].question}
                                                            </Text>
                                                            <Image m={[1, 2]} src={data.questions[17].img} />
                                                            <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                                {data.questions[17].sub1}
                                                            </Text>
                                                            <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                                {data.questions[17].sub2}
                                                            </Text>
                                                            <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                                {data.questions[17].sub3}
                                                            </Text>
                                                            <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                                {data.questions[17].sub4}
                                                            </Text>
                                                        </VStack>
                                                    </>
                                                </HStack>

                                            </>
                                        ) : (
                                            <>
                                                <HStack spacing={3} align='start'>
                                                    <Text color='red' fontWeight='bold'>18.</Text>
                                                    <>
                                                        <VStack>
                                                            <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                                {data.questions[17].question}
                                                            </Text>
                                                            <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                                {data.questions[17].sub1}
                                                            </Text>
                                                            <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                                {data.questions[7].sub2}
                                                            </Text>
                                                            <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                                {data.questions[17].sub3}
                                                            </Text>
                                                            <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                                {data.questions[17].sub4}
                                                            </Text>
                                                        </VStack>
                                                    </>
                                                </HStack>
                                            </>
                                        )}
                                    </>
                                </Box>
                                <>
                                    {data.questions[17].answerimg ? (
                                        <HStack spacing={1} >
                                            <ListIcon as={MdCheckCircle} color='green.500' />
                                            <Image src={data.questions[17].answerimg} />
                                        </HStack>

                                    ) : (
                                        <HStack spacing={1} >
                                            <ListIcon as={MdCheckCircle} color='green.500' />
                                            <Text fontWeight={'bold'} >{data.questions[7].answer}</Text>
                                        </HStack>
                                    )}
                                </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[18].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>19.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[18].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[18].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[18].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[18].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[18].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[18].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>19.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[18].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[18].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[18].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[18].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[18].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[18].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[18].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[18].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[19].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>20.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[19].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[19].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[19].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[19].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[19].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[19].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>20.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[19].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[19].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[19].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[19].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[19].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[19].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[19].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[19].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                    </List>
                </GridItem>
                <GridItem w='100%' >
                    <List spacing={3}>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[20].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>21.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[20].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[20].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[20].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[20].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[20].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[20].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>21.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[20].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[20].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[20].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[20].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[20].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[20].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[20].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[20].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[21].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>22.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[21].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[21].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[21].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[21].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[21].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[21].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>22.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[21].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[21].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[21].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[21].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[21].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[21].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[21].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[21].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[22].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>23.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[22].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[22].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[22].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[22].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[22].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[22].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>23.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[22].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[22].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[22].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[22].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[22].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[22].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[22].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[22].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[23].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>24.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[23].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[23].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[23].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[23].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[23].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[23].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>24.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[23].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[23].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[23].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[23].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[23].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[23].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[23].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[23].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[24].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>25.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[24].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[24].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[24].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[24].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[24].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[24].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>25.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[24].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[24].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[24].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[24].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[24].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[24].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[24].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[24].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[25].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>26.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[25].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[25].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[25].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[25].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[25].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[25].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>26.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[25].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[25].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[25].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[25].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[25].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[25].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[25].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[25].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[26].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>27.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[26].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[26].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[26].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[26].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[26].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[26].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>27.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[26].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[26].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[26].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[26].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[26].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[26].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[26].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[26].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[27].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>28.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[27].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[27].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[27].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[27].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[27].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[27].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>28.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[27].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[27].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[27].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[27].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[27].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[27].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[27].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[27].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[28].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>29.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[28].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[28].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[28].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[28].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[28].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[28].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>29.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[28].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[28].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[28].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[28].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[28].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[28].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[28].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[28].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[29].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>30.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[29].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[29].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[29].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[29].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[29].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[29].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>30.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[29].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[29].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[29].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[29].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[29].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[29].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[29].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[29].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                    </List>
                </GridItem>
                <GridItem w='100%' >
                    <List spacing={3}>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[30].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>31.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[30].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[30].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[30].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[30].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[30].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[30].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>31.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[30].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[30].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[30].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[30].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[30].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[30].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[30].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[30].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[31].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>32.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[31].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[31].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[31].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[31].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[31].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[31].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>32.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[31].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[31].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[31].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[31].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[31].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[31].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[31].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[31].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[32].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>33.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[32].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[32].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[32].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[32].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[32].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[32].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>33.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[32].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[32].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[32].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[32].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[32].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[32].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[32].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[32].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[33].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>34.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[33].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[33].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[33].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[33].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[33].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[33].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>34.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[33].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[33].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[33].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[33].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[33].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[33].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[33].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[33].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[34].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>35.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[34].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[34].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[34].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[34].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[34].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[34].sub4}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[34].sub5}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>35.</Text>
                                                <>
                                                    <VStack>
                                                        
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[34].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[34].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[34].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[34].sub4}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[34].sub5}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[34].question}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[34].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[34].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[34].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[35].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>36.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[35].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[35].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[35].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[35].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[35].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[35].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>36.</Text>
                                                <>
                                                    <VStack>
                                                        
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[35].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[35].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[35].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[35].sub4}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[35].sub5}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[35].question}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[35].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[35].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[35].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[36].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>37.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[36].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[36].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[36].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[36].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[36].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[36].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>37.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[36].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[36].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[36].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[36].sub4}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[36].sub5}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[36].question}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[36].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[36].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[36].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[37].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>38.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[37].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[37].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[37].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[37].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[37].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[37].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>38.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[37].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[37].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[37].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[37].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[37].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[37].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[37].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[37].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[38].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>39.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[38].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[38].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[38].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[38].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[38].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[38].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>39.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[38].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[38].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[38].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[38].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[38].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[38].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[38].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[38].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                        <ListItem>
                            <Box align='center' m={1} >
                                <>
                                    {data.questions[39].img ? (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>40.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[39].question}
                                                        </Text>
                                                        <Image m={[1, 2]} src={data.questions[39].img} />
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[39].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[39].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[39].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[39].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>

                                        </>
                                    ) : (
                                        <>
                                            <HStack spacing={3} align='start'>
                                                <Text color='red' fontWeight='bold'>40.</Text>
                                                <>
                                                    <VStack>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[39].question}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[39].sub1}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[39].sub2}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[39].sub3}
                                                        </Text>
                                                        <Text fontWeight={'bold'} size={'s'} mb={'1'}>
                                                            {data.questions[39].sub4}
                                                        </Text>
                                                    </VStack>
                                                </>
                                            </HStack>
                                        </>
                                    )}
                                </>
                            </Box>
                            <>
                                {data.questions[39].answerimg ? (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Image src={data.questions[39].answerimg} />
                                    </HStack>

                                ) : (
                                    <HStack spacing={1} >
                                        <ListIcon as={MdCheckCircle} color='green.500' />
                                        <Text fontWeight={'bold'} >{data.questions[39].answer}</Text>
                                    </HStack>
                                )}
                            </>
                        </ListItem>
                    </List>
                </GridItem>    
            </Grid>
            <Box m={5}>
                <HStack spacing={30}>
                    <Button size={['xs','sm']}><Link to={data.link}>Back</Link></Button>
                    <Button size={['xs', 'sm']} onClick={() => navigate(-1)}>Retest</Button>
                </HStack>
            </Box>
        </>
     );
}
 
export default ReviseAnswers;