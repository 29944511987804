import { useAuthContext } from '../hooks/useAuthContext';
import { Button, Flex, Grid, Spacer, VStack,Image } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Box, 
  Text
} from '@chakra-ui/react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Timer from '../components/CountDownTime45';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { BeatLoader } from 'react-spinners';

const IntSciJhs = () => {
  const [questions, setQuestions] = useState(null)
  const {user} = useAuthContext()

  useEffect(() => {
    const fetchQuestions = async () => {
      const response = await fetch(process.env.REACT_APP_NOT_SECRET_CODE + '/quizzer/questions/jhs/intscience', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
       }
      }) 
      const data = await response.json()

      if(response.ok) {
        setQuestions(data)
        console.log(data)
      }
    }

    if(user) {
      fetchQuestions()
    }

  }, [user])

  return (  
    <>
      <>
        <HelmetProvider >
          <Helmet><title>Integrated Science Quizzer</title></Helmet>
        </HelmetProvider>
        <Navbar />
      </>
      {!questions ? (
        <Box pt={150} pb={60} align="center">
          <Text>Loading questions...</Text>
          <BeatLoader size={48} color='pink' loading />
        </Box>
      ) : (
        <Box
          maxW={900}
          pt={100}
          pb={60}
          mx={[4, 4, 5, 'auto']}
          borderRadius={5}
        >
          <Accordion allowToggle m={10} >
            {questions && questions.map((question) => (
              <AccordionItem key={question._id}>
                <h2>
                  <AccordionButton _expanded={{ bg: 'teal', color: 'white' }}>
                    <Box flex='1' textAlign='left'>
                      Bece {question.subject} {question.month} {question.year}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box>
                    <VStack>
                      <Heading align={'center'}>INTEGRATED SCIENCE</Heading>
                      <Text>OBJECTIVE TEST</Text>
                      <Text> BECE {question.month} {question.year} </Text>
                    </VStack>
                    <Flex alignItems={'center'}>
                      <Text>1 of 40</Text>
                      <Spacer />
                      <Timer />
                    </Flex>
                    <Text align={'center'} m={5}>{question.questions[0].instructions}</Text>
                  </Box>
                  <Box>
                    <>
                      <Box align='center' m={5}>
                        <Text fontWeight={'bold'} size={'s'} mb={'3'}>
                          {question.questions[0].question}
                        </Text>
                        <Image m={3} src={question.questions[0].img} />
                        <Text fontWeight={'bold'} size={'s'} mb={'3'}>
                          {question.questions[0].sub1}
                        </Text>
                        <Text fontWeight={'bold'} size={'s'} mb={'3'}>
                          {question.questions[0].sub2}
                        </Text>
                        <Text fontWeight={'bold'} size={'s'} mb={'3'}>
                          {question.questions[0].sub3}
                        </Text>
                        <Text fontWeight={'bold'} size={'s'} mb={'3'}>
                          {question.questions[0].sub4}
                        </Text>
                      </Box>
                    </>

                    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
                      <Button >
                        {question.questions[0].optionA}
                      </Button>
                      <Button>
                        {question.questions[0].optionB}
                      </Button>
                      <Button>
                        {question.questions[0].optionC}
                      </Button>
                      <Button>
                        {question.questions[0].optionD}
                      </Button>
                    </Grid>
                    <Button mt={10}>
                      <Link to={'/intsciJhsSheet'} state={question}>Start Quiz </Link>
                    </Button>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      )}     
    </>
   );
}
 
export default IntSciJhs;