import { useState } from "react"
import { useLogin } from "../hooks/useLogin"
import {
   FormControl,
   FormLabel,
   Text,
   Input,
   Button,
   Box,
   useColorModeValue,
   Checkbox,
   Link,
   Stack,
   Heading,
   Flex,
 } from '@chakra-ui/react'
import { Helmet } from "react-helmet-async"
import { BounceLoader } from "react-spinners"


const Login = () => {

  // function handleCallbackResponse(response) {
  //   console.log("Encoded JWT ID token: " + response.credential)
  //   const user = jwt_decode(response.credential);
  //   console.log(user)
  //   login(user.email, user.jti)
  // }

  // useEffect(() => {
  //   /*  global google */
  //   google.accounts.id.initialize({
  //     client_id: "665249124050-0qsjftpvjh124gbasgb4jg74lckbp7h2.apps.googleusercontent.com",
  //     callback: handleCallbackResponse
  //   });
  //   google.accounts.id.renderButton(
  //     document.getElementById("login"),
  //     { theme: "outline" }
  //   )
  // } );

   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')
   const { login, error, isLoading } = useLogin()

   const handleSubmit = async (e) => {
      e.preventDefault()

      await login(email, password)
   }

   return (
  <>
  <Helmet><title>Quizonnet login</title></Helmet>
   <form onSubmit={handleSubmit}>
      <Flex
         minH={'100vh'}
         align={'center'}
         justify={'center'}
         bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Sign in to your account</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            to enjoy all of our cool <Link color={'pink.400'}>features</Link> 
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input 
               variant={'filled'} 
               type="email" 
               onChange={(e) => setEmail(e.target.value)}
               value={email}
            />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <Input 
               variant={'filled'} 
               type="password" 
               onChange={(e) => setPassword(e.target.value)}
               value={password}
               />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
                <Checkbox>Remember me</Checkbox>
                <Link color={'pink.400'}>Forgot password?</Link>
              </Stack>
                <Button
                p={'5'}
                disabled={isLoading}
                type="submit"
                  bg={'pink.300'}
                  color={'black'}
                  _hover={{
                    bg: 'pink.200',
                  }}>
                  {isLoading ? <BounceLoader size={30} /> : 'Sign in'} 
                </Button>
                <Text align={'center'}>
                Dont have an account? <Link href='/signup'> Register </Link>
              </Text>
              <Box>
               {error && <Text color={'red.400'} textAlign={'center'}>{error}</Text>}
               </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
   </form> 
   </>      
   )
}


export default Login